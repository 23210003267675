import { render, staticRenderFns } from "./itemPanel.vue?vue&type=template&id=11d6d9bf&scoped=true&"
import script from "./itemPanel.vue?vue&type=script&lang=js&"
export * from "./itemPanel.vue?vue&type=script&lang=js&"
import style0 from "./itemPanel.vue?vue&type=style&index=0&id=11d6d9bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11d6d9bf",
  null
  
)

export default component.exports
<template>
  <div class="item-panel">
    <div style="color: #B1D9FF;font-size: 0.14rem;font-weight: Bold;padding:0.08rem 0 0.08rem 0.28rem" class="title">
      {{ title }}
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'item-panel',
  props: {
    title: {
      type: String,
      default () {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.item-panel {
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url(../../../assets/img/city/item.png);
  background-repeat: no-repeat;
  background-size: 100% 140%;
  .title{
    background-image: url(../../../assets/img/city/item-title.png);
    background-position: bottom left;
    background-repeat: no-repeat;
  }
}
</style>
